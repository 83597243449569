.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #e8c3e8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* card border shadow */
.card {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.form-group{
  margin-top: 15px;
  margin-bottom: 15px;
  
}
.input-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  margin-top: 10px;
}


.drop_box {
  margin: 10px 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
}

.drop_box h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.drop_box p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #a3a3a3;
}

.btn-upload {
  text-decoration: none;
  background-color: #fff;
  color: #898989;
  padding: 10px 20px;
  border: none;
  outline: none;
  transition: 0.3s;
  border-radius: 5px;
}

.btn-upload:hover{
  text-decoration: none;
  background-color: #ffffff;
  color: purple;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;

}

.form-body{
  padding: 50px;
  min-height: 100vh;
}

.btn-primary{
  background-color: purple !important;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.3s;
}

.btn-outline-primary{
  background-color: #fff;
  color: purple;
  border: 1px solid purple !important;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.3s;
}

.btn-primary:hover{
  background-color: purple !important;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.3s;
}

.btn-outline-primary:hover{
  background-color: #fff;
  color: purple;
  border: 1px solid purple !important;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.3s;
}

body {
  background-color:rgba(255, 193, 255, 0.269);
  font-family:"Poppins", sans-serif !important;
  font-size: 14px;
  
}

a:hover, a:visited, a:link {
  text-decoration:none;
  color: purple;
}

#signature {
  width:800px !important;
  height: 230px !important;
  /* margin: 100px auto !important; */
  padding: 15px 15px !important;
  background-color:rgb(207, 207, 207);
}

#logo {
  float:left;
  margin-right:15px
}

#logo img {
  /* margin-top: 10px; */
  width:200px;
  height:200px;
}

#content p {
  margin:0;
  padding:0;
}

#content #title {
  text-transform:uppercase;
  letter-spacing:2px;
  font-size: large;
  color: #3d383f;
  /* margin-top:15px */
}

#content #subtitle {
  font: italic 1.2em Poppins, serif;
  margin-top: 5px;
  color:rgb(117, 117, 117);
}

#content #contact {
  margin-top:10px;
  color:rgb(117, 117, 117);
}

#content #web {
  float:left;
  margin-left: 5px;
  margin-top: 5px;
}

#content #social-icons {
  float:left;  
  margin-left: 5px;
  margin-top: 10px;
}

#content #social-icons a {
  height:30px;
  line-height:1.4em;
  display:inline-block !important;
  padding-right:20px;
}

/* make i copy able */
#content #social-icons a i {
  pointer-events: none !important;
}

input, select{
  border: none !important;
  border-bottom: 1px solid purple !important;
  outline: none !important;
  background: transparent !important;
  font-size: 15px !important;
  font-family: Poppins !important;
  color: #000 !important;
  padding: 0px !important;
  margin: 0px !important;
}

input, select,
input::placeholder {
    font: 15px/3 sans-serif !important;
    color: #282c3445 !important;
    /* text-transform: lowercase !important; */
}

h5{
  font-size: 15px !important;
  font-weight: 800 !important;
  color: purple !important;
  padding: 0px !important;
  margin: 0px !important;
  text-decoration: underline ;
  text-decoration-color: purple;
}

.btn-purple{
  color: #fff !important;
  background-color: purple;
  border: none !important;
}

.btn-purple:hover{
  color: purple !important;
  background-color: white;
  border: 2px purple !important;
}
